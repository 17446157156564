var _Menu = (function()
{
	var el = {};

	var callback = function()
	{
		initDOM();

		if (el.menu_box.length > 0)
			initListeners();
	};

	var initDOM = function()
	{
		el = {};
		el.menu_box = $('[data-menu]');
	};

	var initListeners = function()
	{
		$(document)
			.on('click', '[data-menu-toggle]', function() { toggleMenu(); })
			.on('click', '[data-menu-close]', function() { hideMenu(); })
			.on('click', '[data-navbar]', function() { hideMenu(); });
	};

	var showMenu = function()
	{
		$('[data-menu]').addClass('is-open');
		ButtonCross();

		var tlShowMenu = new TimelineMax();
			tlShowMenu
				.to(el.menu_box, .6, { y: '290', boxShadow: '0 0 48px rgba(0,0,0,.2)' });
	};

	var hideMenu = function()
	{
		var tlHideMenu = new TimelineMax();
			tlHideMenu
				.to(el.menu_box, .6, { y: '0', boxShadow: '0 0 0 rgba(0,0,0,.2)' });

		$('[data-menu]').removeClass('is-open');
		ButtonBars();
	};
	var ButtonCross = function()
	{
		var tlButtonCross = new TimelineMax();
			tlButtonCross
				.to('.bar[data-type="1"]', .2, { y: '6px', rotation: -45 })
				.to('.bar[data-type="2"]', .2, { autoAlpha: 0 }, '=-.2')
				.to('.bar[data-type="3"]', .2, { y: '-6px', rotation: 45 }, '=-.2');
	};

	var ButtonBars = function()
	{
		var tlButtonBars = new TimelineMax();
			tlButtonBars
				.to('.bar[data-type="1"]', .2, { y: '0', rotation: 0 })
				.to('.bar[data-type="2"]', .2, { autoAlpha: 1 }, '=-.2')
				.to('.bar[data-type="3"]', .2, { y: '0', rotation: 0 }, '=-.2');
	};

	var toggleMenu = function()
	{
		if (el.menu_box.hasClass('is-open'))
			hideMenu();
		else
			showMenu();
	};

	$(document).ready(callback);
	$(document).on('pjax:callback', callback);

	return {
		show: showMenu,
		hide: hideMenu,
	};
})();
