var _Pjax = (function()
{
	var pjax = null;
	var baseUrl = '';

	var tlPjaxStart = null;

	var init = function()
	{
		$(document).ready(function()
		{
			initPjax();
			initEvents();

			setMenuActive();
		});

		return {
			href: href,
			refresh: refresh,
		};
	};

	var initPjax = function()
	{
		baseUrl = $('head').find('base').attr('href');

		pjax = new Pjax({
			elements: '_LetTheEventsDoIt_',
			selectors: ['head title', '#app'],
			// debug: true,
		});

		$(document)
			.on('pjax:send', pjaxSend)
			.on('pjax:complete', pjaxComplete)
			.on('pjax:success', pjaxSuccess)
			.on('pjax:error', pjaxError);
	};

	var initEvents = function()
	{
		$(document)
			.on('click', 'a[pjax]', goTo)
			.on('click', '[data-href]', goTo);
	};

	var animStart = function()
	{
		tlPjaxStart = new TimelineLite();
		tlPjaxStart
			.set('.loader-bar', { width: 0, height: '4px', top: '50%', marginTop: -2 })
			.set('.upper-panel', { y: '-100%' })
			.set('.lower-panel', { y: '100%' })
			.set('.module-transition', { display: 'block' })
			.to('.upper-panel, .lower-panel', .2, { y: '0%' })
			.to('.loader-bar', .6, { width: '80%' });
	};

	var animEnd = function()
	{
		tlPjaxStart
			.set('main', { scrollTo: 0 })
			.set('.loader-bar', { width: '80%' })
			.to('.loader-bar', .2, { width: '100%' })
			.to('.loader-bar', .4, { height: '100%', top: '0%', marginTop: 0 })
			.to('.module-transition', .4, { autoAlpha: 0 })
			.set('.module-transition', { autoAlpha: 1, display: 'none' });

		_Menu.hide();
	};

	var pjaxSend = function()
	{
		animStart();
	};

	var pjaxComplete = function()
	{
		$('*').off('click keyup keydown mouseenter mouseleave touch touchstart touchend submit change focus scroll');
		$(document).off('click keyup keydown mouseenter mouseleave touch touchstart touchend submit change focus scroll');
		$(window).off('resize');

		initEvents();
		setMenuActive();
		animEnd();

		$(document).trigger('pjax:callback');
	};

	var pjaxSuccess = function()
	{

	};

	var pjaxError = function(error)
	{
		console.warn('Pjax error:', error);
	};

	var setMenuActive = function()
	{
		$('[data-nav-link]').removeClass('is-active');
		$('[data-nav-link~="' + _App.getCurrentMenu() + '"]').addClass('is-active');
	};

	var goTo = function(e)
	{
		e.preventDefault();
		href($(this).attr('href') || $(this).attr('data-href'));
	};

	var href = function(url, force)
	{
		if (force === true)
			pjax.latestChance(url);
		else
			pjax.loadUrl(baseUrl + $.trim(url, '/'), pjax.options);
	};

	var refresh = function(force)
	{
		href(window.location.href, force);
	};

	return init();
})();
