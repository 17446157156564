var _FixingList = (function()
{
	var el = {};

	var monthNames = [
		'January', 'February', 'March',
		'April', 'May', 'June', 'July',
		'August', 'September', 'October',
		'November', 'December'
	];

	var callback = function()
	{
		initDOM();

		if (el.fixing_box.length > 0) {
			initFixing();
		}
	};

	var initDOM = function()
	{
		el = {};

		el.fixing_box = $('[data-fixing-list]');
		el.fixing_date = $('[data-fixing-date]');
		el.fixing_error = $('[data-fixing-error]');
		el.fixing_loading = $('[data-fixing-loading]');
	};

	var initFixing = function()
	{
		showError(false);
		showLoading(true);

		$.ajax({
			url: 'ajax/fixing',
			type: 'GET',
			timeout: 60 * 1000,
			cache: false,
			success: function(data)
			{
				displayCurrencies(data);
			},
			error: function()
			{
				console.error('Sorry, an error occured during fixing load...');
				showError(true);
			},
			complete: function()
			{
				showLoading(false);
			},
		});
	};

	var displayCurrencies = function(data)
	{
		var currencies = data.fixing;
		var legends = data.legends;
		var rows = [];

		for (var i = 0, ci = currencies.length; i < ci; i++) {
			var currency = currencies[i];
			var currencyLegendValues = [];
			var currencyFixingValues = [];
			var currencyBidValues = [];
			var currencyAskValues = [];

			for (var j = 0, cj = legends.length; j < cj; j++) {
				var legend = legends[j];
				var currencyFixing = currency.fixings[legend] || 0;
				var currencyBid = currency.bid[legend] || 0;
				var currencyAsk = currency.ask[legend] || 0;

				currencyLegendValues.push(
					'<div class="value">' +
						legend +
					'</div>'
				);

				currencyFixingValues.push(
					'<div class="value is-text-right ' + (currencyFixing < 0 && 'is-negative') + '">' +
						_Utils.formatNum(currencyFixing, 3) +
					'</div>'
				);

				currencyBidValues.push(
					'<div class="value is-text-right ' + (currencyBid < 0 && 'is-negative') + '">' +
						_Utils.formatNum(currencyBid, 3) +
					'</div>'
				);

				currencyAskValues.push(
					'<div class="value is-text-right ' + (currencyAsk < 0 && 'is-negative') + '">' +
						_Utils.formatNum(currencyAsk, 3) +
					'</div>'
				);
			}

			rows.push(
				'<div class="default-container module-fixing">' +
					'<div class="g">' +
						'<div class="g-100">' +
							'<h1 class="fifth-title">' + currency.name + '</h1>' +
						'</div>' +
					'</div>' +
					'<div class="g default-module-text fixing-container">' +
						'<div class="fixing">' +
							'<div class="label">Tenor</div>' +
							currencyLegendValues.join('') +
						'</div>' +
						'<div class="fixing">' +
							'<div class="label is-text-right">MID</div>' +
							currencyFixingValues.join('') +
						'</div>' +
						'<div class="fixing">' +
							'<div class="label is-text-right">BID</div>' +
							currencyBidValues.join('') +
						'</div>' +
						'<div class="fixing">' +
							'<div class="label is-text-right">ASK</div>' +
							currencyAskValues.join('') +
						'</div>' +
					'</div>' +
				'</div>'
			);
		}

		el.fixing_box.empty().append(rows);

		var lastDate = _Utils.parseDate(data.last_date);
			lastDate = formatFullNumber(lastDate.getDate()) + ' ' + monthNames[lastDate.getMonth()] + ' ' + lastDate.getFullYear() + ' ' + formatFullNumber(lastDate.getHours()) + ':' + formatFullNumber(lastDate.getMinutes());

		el.fixing_date.text(lastDate);
	};

	var showLoading = function(visible)
	{
		el.fixing_loading[visible === false ? 'addClass' : 'removeClass']('is-hidden');
	};

	var showError = function(visible)
	{
		el.fixing_error[visible === false ? 'addClass' : 'removeClass']('is-hidden');
	};

	var formatFullNumber = function(num)
	{
		return String('0' + String(num)).substr(-2);
	};

	$(document).ready(callback);
	$(document).on('pjax:callback', callback);
})();
