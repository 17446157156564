var _Blog = (function()
{
	var el = {};
	var isUpdating = false;
	var paginationOffset = 1000;
	var paginationCount = 10;
	var paginationNow = '';
	var articlesCount = 0;
	var windowHeight = $(window).height();

	var callback = function()
	{
		initDOM();

		if (el.pagination.length > 0) {
			initListeners();
			initBlog();
		}
	};

	var initDOM = function()
	{
		el = {};

		el.loader = $('[data-blog-loader]');
		el.pagination = $('[data-blog-pagination]');
		el.articlesBox = $('[data-blog-articles]');
		el.no_post = $('[data-blog-no-post]');
	};

	var initListeners = function()
	{
		$(window).on('resize', function()
		{
			windowHeight = $(window).height();
		});

		$(document).on('scroll', function()
		{
			var documentPos = (window.pageYOffset || document.documentElement.scrollTop) + windowHeight;
			var bottomPos = el.pagination.position().top + el.pagination.height();
			var trigger = documentPos > (bottomPos - paginationOffset);

			if (trigger === true && isUpdating === false)
				loadMorePosts();
		});
	};

	var initBlog = function()
	{
		isUpdating = false;
		articlesCount = 0;
		paginationNow = (new Date()).toJSON();

		emptyPosts();
		loadMorePosts();
	};

	var loadMorePosts = function()
	{
		// Show loader
		showLoader(true);
		isUpdating = true;

		// Hide no post message
		el.no_post.addClass('is-hidden');

		// Get new posts
		$.ajax({
			url: 'ajax/blog/more',
			data: {
				now: paginationNow,
				count: paginationCount,
				offset: articlesCount,
			},
			type: 'GET',
			timeout: 60 * 1000,
			cache: false,
			success: function(data)
			{
				var $articles = $(data).find('[data-blog-article]');
					$articles.appendTo(el.articlesBox);

				if (paginationCount === $articles.length)
					isUpdating = false;

				articlesCount += $articles.length;

				if (articlesCount === 0 && $articles.length === 0)
					el.no_post.removeClass('is-hidden');
			},
			error: function()
			{
				console.error('Sorry, an error occured during more posts load...');
			},
			complete: function()
			{
				showLoader(false);
			},
		});
	};

	var emptyPosts = function(visible)
	{
		el.articlesBox.empty();
	};

	var showLoader = function(visible)
	{
		el.loader[visible === false ? 'addClass' : 'removeClass']('is-hidden');
	};

	$(document).ready(callback);
	$(document).on('pjax:callback', callback);
})();
