var _FixingRibbon = (function()
{
	var el = {};
	var tlShowMenu = null;

	var yearsToDisplay = ['2Y', '5Y', '10Y', '15Y', '30Y'];

	var callback = function()
	{
		initDOM();

		if (el.fixing_box.length > 0) {
			initFixing();
		}
	};

	var initDOM = function()
	{
		el = {};

		el.fixing_box = $('[data-fixing-ribbon]');
		el.fixing_error = $('[data-fixing-error]');
		el.fixing_loading = $('[data-fixing-loading]');
	};

	var initFixing = function()
	{
		showError(false);
		showLoading(true);

		$.ajax({
			url: 'ajax/fixing',
			type: 'GET',
			timeout: 60 * 1000,
			cache: false,
			success: function(data)
			{
				displayCurrencies(data);
			},
			error: function()
			{
				console.error('Sorry, an error occured during fixing load...');
				showError(true);
			},
			complete: function()
			{
				showLoading(false);
			},
		});
	};

	var displayCurrencies = function(data)
	{
		var currencies = data.fixing;
		var rows = [];

		for (var i = 0, ci = yearsToDisplay.length; i < ci; i++) {
			var yearToDisplay = yearsToDisplay[i];

			for (var j = 0, cj = currencies.length; j < cj; j++) {
				var currency = currencies[j];
				var currencyYear = currency.fixings[yearToDisplay] || 0;

				rows.push(
					'<div class="fixing">' +
						'<div class="top">' +
							'<div class="label">' +
								currency.name +
							'</div>' +
							'<div class="icon">' +
							'</div>' +
						'</div>' +
						'<div class="bottom">' +
							'<div class="number-1">' +
								yearToDisplay +
							'</div>' +
							'<div class="number-1 ' + (currencyYear < 0 && 'is-negative') + '">' +
								_Utils.formatNum(currencyYear, 3) +
							'</div>' +
						'</div>' +
					'</div>'
				);
			}
		}

		el.fixing_box.empty().append(rows);
		startAutoDragging();
	};

	var startAutoDragging = function()
	{
		tlShowMenu = new TimelineMax();
		autoDrag();
	};

	var autoDrag = function()
	{
		tlShowMenu
			.set(el.fixing_box, { marginLeft: '0px' })
			.to(el.fixing_box, 1, { marginLeft: '-120px', ease: 'linear', onComplete: loopAutoDragging })
	};

	var loopAutoDragging = function()
	{
		el.fixing_box.find('.fixing').first().detach().appendTo(el.fixing_box);
		autoDrag();
	};

	var showLoading = function(visible)
	{
		el.fixing_loading[visible === false ? 'addClass' : 'removeClass']('is-hidden');
	};

	var showError = function(visible)
	{
		el.fixing_error[visible === false ? 'addClass' : 'removeClass']('is-hidden');
	};

	$(document).ready(callback);
	$(document).on('pjax:callback', callback);

	return {
		startAutoDragging: startAutoDragging,
	}
})();
