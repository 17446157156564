var _Share = (function()
{
	var el = {};
	var shareWindowWidth = 600;

	/*
	 * initializations
	 */
	var callback = function()
	{
		initDOM();

		if (el.share_facebook.length > 0)
			initListeners();
	};

	/*
	 * init DOM elements
	 */
	var initDOM = function()
	{
		el = {};

		el.share_facebook = $('[data-share="facebook"]');
		el.share_twitter = $('[data-share="twitter"]');
		el.share_whatsapp = $('[data-share="linkedin"]');
	};

	/*
	 * init DOM events
	 */
	var initListeners = function()
	{
		el.share_facebook.on('click', shareFacebook);
		el.share_twitter.on('click', shareTwitter);
		el.share_whatsapp.on('click', shareLinkedIn);
	};

	/*
	 * get box position
	 */
	var getBoxPosition = function()
	{
		return ($(window).width() - shareWindowWidth) / 2;
	};

	/*
	 * get current url
	 */
	var getCurrentUrl = function()
	{
		return window.location.href;
	};

	/*
	 * share facebook
	 */
	var shareFacebook = function()
	{
		requestLink('https://facebook.com/sharer.php?u=');
	};

	/*
	 * share twitter
	 */
	var shareTwitter = function()
	{
		requestLink('https://twitter.com/intent/tweet?via=GottexBrokers&url=');
	};

	/*
	 * share linked in
	 */
	var shareLinkedIn = function()
	{
		requestLink('https://www.linkedin.com/cws/share?url=');
	};

	/*
	 * open share modal
	 */
	var openShareModal = function(socialUrl, shareUrl)
	{
		var leftPos = getBoxPosition();
		window.open(socialUrl + shareUrl, '_blank', 'status=0, menubar=0, toolbar=0, resizable=1, scrollbars=1, top=100, left=' + leftPos + ', width=' + shareWindowWidth + ', height=370');
	};

	/*
	 * request share link
	 */
	var requestLink = function(socialUrl)
	{
		openShareModal(socialUrl, getCurrentUrl());
	};

	$(document).ready(callback);
	$(document).on('pjax:callback', callback);
})();
