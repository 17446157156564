var _Modal = (function()
{
	var el = {};

	var callback = function()
	{
		initDOM();

		if (el.modal_box.length > 0)
			initListeners();
	};

	var initDOM = function()
	{
		el = {};
		el.modal_box = $('[data-modals-box]');
	};

	var initListeners = function()
	{
		$(document)
			.on('click', '[data-modal-trigger]', function() { showModal($(this)); })
			.on('click', '[data-modals-box] [data-modal-overlay]', function() { hideModal(); })
			.on('click', '[data-modals-box] [data-modal-close]', function() { hideModal(); })
			.on('keyup', function(e) { if (e.keyCode === 27) hideModal(); });
	};

	var showModal = function(modalId, content)
	{
		if (typeof modalId !== 'string' && typeof modalId !== 'number')
			modalId = getModalId(modalId);

		var $modal = $('[data-modal-id="' + modalId + '"]');
		if ($modal.length === 0) return;

		hideModal();

		if (typeof content === 'string')
			$modal.find('[data-modal-content]').html(content);

		$modal.removeClass('is-hidden');
		$('[data-modals-box]').removeClass('is-hidden');
	};

	var hideModal = function()
	{
		$('[data-modal-id]').addClass('is-hidden');
		$('[data-modals-box]').addClass('is-hidden');
	};

	var getModalId = function($modal)
	{
		return $modal.attr('data-modal-id') || $modal.attr('data-modal-trigger');
	};

	$(document).ready(callback);
	$(document).on('pjax:callback', callback);

	return {
		show: showModal,
		hide: hideModal,
	};
})();
