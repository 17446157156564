var _Utils = (function()
{
	var parseDate = function(date)
	{
		return new Date(date.split('-').join('/'));
	};

	var deepValue = function(obj, path)
	{
		path = path.split('.');

		for (var i = 0, c = path.length; i < c; i++)
			obj = obj[path[i]];

		return obj;
	};

	var sortByAttr = function(arr, path, caseInsensitive)
	{
		return arr.sort(function(a, b)
		{
			var x = deepValue(a, path);
			var y = deepValue(b, path);

			if (caseInsensitive) {
				x = x.toLowerCase();
				y = y.toLowerCase();
			}

			return x < y ? -1 : x > y ? 1 : 0;
		});
	};

	var formatNum = function(price, c, d, t)
	{
		var n = price,
			c = isNaN(c = Math.abs(c)) ? 2 : c,
			d = d == undefined ? "." : d,
			t = t == undefined ? "'" : t,
			s = n < 0 ? "-" : "",
			i = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c))),
			j = (j = i.length) > 3 ? j % 3 : 0;

		return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
	};

	var isMobile =
	{
		agent: navigator.userAgent.toLowerCase(),
		android: function()
		{
			return Boolean(this.agent.match(/android/i));
		},
		blackberry: function()
		{
			return Boolean(this.agent.match(/blackberry/i));
		},
		ios: function()
		{
			return Boolean(this.agent.match(/iphone|ipad|ipod/i));
		},
		opera: function()
		{
			return Boolean(this.agent.match(/opera mini/i));
		},
		windows: function()
		{
			return Boolean(this.agent.match(/iemobile/i) || this.agent.match(/wpdesktop/i));
		},
		any: function()
		{
			return Boolean((this.android() || this.blackberry() || this.ios() || this.opera() || this.windows()));
		}
	};

	return {
		parseDate: parseDate,
		sortByAttr: sortByAttr,
		formatNum: formatNum,
		isMobile: isMobile,
	};
})();
