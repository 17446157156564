var _App = (function()
{
	var el = {};
	var currentMenu = '';
	var langId = '';
	var langCode = '';
	var firstAnnouncement = '';

	var callback = function()
	{
		initDOM();
		initData();
	};

	var initDOM = function()
	{
		el = {};
		el.config = $('[data-page-config]');
	};

	var initData = function()
	{
		checkData();

		currentMenu = el.config.attr('data-current-menu');
		langId = el.config.attr('data-lang-id');
		langCode = el.config.attr('data-lang-code');
		firstAnnouncement = el.config.attr('data-app-announcement');
	};

	var checkData = function()
	{
		if (el.config.length < 1) console.warn('_App has no data.');
		if (el.config.length > 1) console.warn('_App has more than one data.');
	};

	var getCurrentMenu = function()
	{
		return currentMenu;
	};

	var getLangId = function()
	{
		return langId;
	};

	var getLangCode = function()
	{
		return langCode;
	};

	var getFirstAnnouncement = function()
	{
		return firstAnnouncement;
	};

	callback();
	$(document).on('pjax:callback', callback);

	return {
		checkData: checkData,
		getCurrentMenu: getCurrentMenu,
		getLangId: getLangId,
		getLangCode: getLangCode,
		//getFirstAnnouncement: getFirstAnnouncement,
	};
})();
