var _Contact = (function()
{
	var el = {};
	var trans = {};
	var isSending = false;

	var callback = function()
	{
		initDOM();

		if (el.send.length > 0) {
			initTrans();
			initListeners();
		}
	};

	var initDOM = function()
	{
		el = {};

		el.type = $('[data-contact="type"]');
		el.email = $('[data-contact="email"]');
		el.subject = $('[data-contact="subject"]');
		el.message = $('[data-contact="message"]');

		el.send = $('[data-contact="send"]');
		el.alert = $('[data-contact="alert"]');
		el.loader = $('[data-contact="loader"]');
		el.button = $('[data-contact="button"]');
	};

	var initTrans = function()
	{
		trans = {};

		trans.error = $('[data-trans="contact-alert-error"]').text();
		trans.success_url = $('[data-contact-url]').attr('data-contact-url');
	};

	var initListeners = function()
	{
		el.send.on('click', sendNewContact);
	};

	var getValues = function()
	{
		return {
			type: el.type.find('option:selected').val(),
			email: el.email.val(),
			subject: el.subject.val(),
			message: el.message.val(),
		};
	};

	var resetValues = function()
	{
		el.email.val('');
		el.subject.val('');
		el.message.val('');
	};

	var sendNewContact = function()
	{
		if (isSending === true) return;
		isSending = true;

		// Show loader
		hideAlert();
		showLoader(true);

		// Get values
		var values = getValues();

		// Send new comment
		$.ajax({
			url: 'ajax/contact',
			data: values,
			type: 'POST',
			timeout: 60 * 1000,
			cache: false,
			success: function(data)
			{
				resetValues();
				_Pjax.href(trans.success_url);
			},
			error: function(error)
			{
				if (error.responseJSON !== undefined)
					showAlert(error.responseJSON && error.responseJSON.errors ? error.responseJSON.errors.join('<br />') : trans.error, false);
				else
					showAlert(trans.error, false);
			},
			complete: function()
			{
				isSending = false;
				showLoader(false);
			},
		});
	};

	var showLoader = function(visible)
	{
		el.loader[visible === false ? 'addClass' : 'removeClass']('is-hidden');
		el.button[visible === false ? 'removeClass' : 'addClass']('is-hidden');
	};

	var showAlert = function(message, isSuccess)
	{
		el.alert
			.removeClass('alert-error alert-success is-hidden')
			.addClass(isSuccess === true ? 'alert-success' : 'alert-error')
			.html(message || '')
			.removeClass('is-hidden');
	};

	var hideAlert = function()
	{
		el.alert.addClass('is-hidden');
	};

	$(document).ready(callback);
	$(document).on('pjax:callback', callback);
})();
