var _Announcement = (function()
{
	var el = {};

	var callback = function()
	{
		initDOM();

		if (el.announcement_box.length > 0)
			initListeners();
	};

	var initDOM = function()
	{
		jmsp = {};
		jmsp.announcement_box = '[data-announcement]';
		jmsp.announcement_trigger = '[data-announcement-trigger]';
		jmsp.announcement_close = '[data-announcement-close]';
		jmsp.announcement_overlay = '[data-announcement-overlay]';

		el = {};
		el.announcement_box = $(jmsp.announcement_box);
		el.announcement_trigger = $(jmsp.announcement_trigger);
		el.announcement_close = $(jmsp.announcement_close);
		el.announcement_overlay = $(jmsp.announcement_overlay);
	};

	var initListeners = function()
	{
		$(document)
			.on('click touchstart', jmsp.announcement_trigger, function() { showAnnouncement(); })
			.on('click touchstart', jmsp.announcement_close, function() { hideAnnouncement(); });

		TweenLite.set(el.announcement_box, { y: '-100%'})
	};

	var showAnnouncement = function(withAnim)
	{
		var tlShowAnnouncement = new TimelineMax({});
			tlShowAnnouncement
				.set(el.announcement_overlay,  { height: '100%' })
				.to(el.announcement_box, .6, { y: '0%' })
				.to(el.announcement_overlay, .6, { autoAlpha: 1 }, '=-.6')
			//	.to('main', .6, { scale: .9, transformOrigin: '50% 0' }, '=-.6');

		if (withAnim === false)
			tlShowAnnouncement.progress(1);
	};

	var hideAnnouncement = function()
	{
		var tlHideAnnouncement = new TimelineMax({});
			tlHideAnnouncement
				.to(el.announcement_box, .6, { y: '-100%' })
				.to(el.announcement_overlay, .6, { autoAlpha: 0}, '=-.6')
				.set(el.announcement_overlay,  { height: '0%' })
			//	.to('main', .6, { scale: 1 }, '=-.6');
	};

	$(document).ready(callback);
	$(document).on('pjax:callback', callback);

	return {
		show: showAnnouncement,
	};
})();
