var _Markerts = (function()
{
	var el = {};
	var clockInterval = null;
	var marketInterval = null;
	var refreshIntervalTime = 1000;
	var oldMinute = 0;

	var markets = {
		'stockholm': {
			offset: '+1',
			open: '08:00-17:00',
			interrupt: '',
			dst: true,
		},
		'london': {
			offset: '+0',
			open: '07:00-18:00',
			interrupt: '',
			dst: true,
		},
		'hongkong': {
			offset: '+8',
			open: '08:30-17:00',
			interrupt: '',
			dst: false,
		},
		'frankfurt': {
			offset: '+1',
			open: '08:00-18:00',
			interrupt: '',
			dst: true,
		},
		'lausanne': {
			offset: '+1',
			open: '08:00-18:00',
			interrupt: '',
			dst: true,
		},
		'newyork': {
			offset: '-5',
			open: '07:00-17:00',
			interrupt: '',
			dst: true,
		},
		'tokyo': {
			offset: '+9',
			open: '08:30-17:00',
			interrupt: '',
			dst: false,
		},
		'oslo': {
			offset: '+1',
			open: '09:00-17:30',
			interrupt: '',
			dst: true,
		},
	};

	var callback = function()
	{
		initDOM();

		stopClock();
		stopMarkets();

		if (el.market_box.length > 0) {
			initClock();
			initMarkets();
			initListeners();
		}
	};

	var initDOM = function()
	{
		el = {};
		el.market_box = $('.module-hours');
		el.illustrations = $('[data-anim-hours]');
	};

	var initListeners = function()
	{
		$(document)
			.on('mouseenter touchstart', '[data-market]', function() { displayIllustration($(this)); });
	};

	var initClock = function()
	{
		// Set background
		TweenLite.set($('[data-background="stockholm"]').siblings(), { y: '-100%' });

		// Init clock
		stopClock();
		updateClock();

		clockInterval = setInterval(updateClock, refreshIntervalTime);
	};

	var initMarkets = function()
	{
		oldMinute = 0;

		stopMarkets();
		updateMarkets();

		marketInterval = setInterval(updateMarkets, refreshIntervalTime);
	};

	var updateMarkets = function()
	{
		var now = getNow();

		if (oldMinute === now.minutes) return;
			oldMinute = now.minutes;

		for (var i = 0, k = Object.keys(markets), c = k.length; i < c; i++) {
			var market = markets[k[i]];

			hours = getOffsetHours(now, market);

			var hourTime = hours + ':' + now.minutes;

			var isOpened = isInHours(market.open, hourTime);
			var isInterrupted = isInHours(market.interrupt, hourTime);

			$('[data-market="' + k[i] + '"]')
				[isOpened === true && isInterrupted === false ? 'addClass' : 'removeClass']('is-open')
				.find('[data-market-hours]')
				.html(prependZeros(hours, 2) + ':' + prependZeros(now.minutes, 2) + ' <small>GMT' + market.offset + '</small>');
		}
	};

	var displayIllustration = function($illuID)
	{
		if ($illuID.hasClass('is-active'))
			return;

		var $illuCity = $('[data-background="' + $illuID.attr('data-market') + '"]');
		var $activeIllu = $('[data-anim-hours][data-visible="yes"]');

		var tldisplayIllustration = new TimelineMax({});
			tldisplayIllustration
				.set($illuCity, { y: '100%' })
				.to($activeIllu, .6, { y: '-100%' })
				.to($illuCity, .6,  { y: '0%' }, '=-.6');

		$activeIllu.attr('data-visible', 'no');
		$illuCity.attr('data-visible', 'yes');

		$('[data-market]').removeClass('is-active');
		$illuID.addClass('is-active');
	};

	var updateClock = function()
	{
		var now = getNow();
		var nowClock = getNowClock(now);

		TweenLite.set('.hours', { rotation: nowClock.hours });
		TweenLite.set('.minutes', { rotation: nowClock.minutes });

		if (_Utils.isMobile.any() === true)
			$('.seconds').addClass('is-hidden');
		else
			TweenLite.set('.seconds', { rotation: nowClock.seconds });
	};

	var isInHours = function(range, target)
	{
		if (range.indexOf('-') === -1)
			return false;

		var now = getNow();

		range = range.split('-');

		var rangeFrom = range[0].split(':');
		var rangeTo = range[1].split(':');
		var target = target.split(':');

		rangeFrom = new Date(now.year, now.month, now.day, rangeFrom[0], rangeFrom[1]);
		rangeTo = new Date(now.year, now.month, now.day, rangeTo[0], rangeTo[1]);
		tzNow = new Date(now.year, now.month, now.day, target[0], target[1]);

		return tzNow >= rangeFrom && tzNow <= rangeTo;
	};

	var getOffsetHours = function(now, market)
	{
		var hours = now.hours - (market.dst === false ? Math.abs(now.offset / 60) : 1) + Number(market.offset);

		if (hours > 23) hours = hours - 24;
		if (hours < 0) hours = hours + 24;

		return hours;
	};

	var getNow = function()
	{
		var now = new Date();

		return {
			now: now,
			year: now.getFullYear(),
			month: now.getMonth(),
			day: now.getDate(),
			hours: now.getHours(),
			minutes: now.getMinutes(),
			seconds: now.getSeconds(),
			offset: now.getTimezoneOffset(),
		};
	};

	var getNowClock = function(now)
	{
		if (now === undefined)
			now = getNow();

		return {
			hours: (now.hours * (360 / 12)) + (now.minutes * (360 / 12 / 60)),
			minutes: now.minutes * (360 / 60),
			seconds: now.seconds * (360 / 60),
		};
	};

	var stopClock = function()
	{
		clearInterval(clockInterval);
	};

	var stopMarkets = function()
	{
		clearInterval(marketInterval);
	};

	var prependZeros = function(str, count)
	{
		return String(Array(count + 1).join('0') + str).substr(-count);
	};

	$(document).ready(callback);
	$(document).on('pjax:callback', callback);

	return {
		getNow: getNow,
	};
})();
