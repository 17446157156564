var _SwitchExperience = (function()
{
	var el = {};
	var isAnimLocked = false;

	var callback = function()
	{
		initDOM();

		if (el.experience_box.length > 0) {
			initExperience();
			initListeners();
		}
	};

	var initDOM = function()
	{
		el = {};
		el.experience_box = $('.experience-selection');
		el.triggerSwitzerland = $('[data-trigger-switzerland]');
		el.triggerSweden = $('[data-trigger-sweden]');
		el.imageSwitzerland = $('[data-image-switzerland]');
		el.imageSweden = $('[data-image-sweden]');
		el.changeBackground = $('[data-change-background]');
		el.changeFill = $('[data-change-fill]');
		el.changeBorder = $('[data-change-border]');
		el.titleSwitzerland = $('[data-title-switzerland]');
		el.titleSweden = $('[data-title-sweden]');
		el.changeBrand = $('[data-change-brand-background]');
	};

	var initListeners = function()
	{
		$(document)
		.on('click touchend', '[data-trigger-switzerland]', function() { displaySwitzerland(); })
		.on('click touchend', '[data-trigger-sweden]', function() { displaySweden(); });
	};

	var initExperience = function()
	{
		TweenLite.set(el.imageSweden, { y: '-100%' });
		TweenLite.set(el.imageSwitzerland, { y: '0%' });
		TweenLite.set(el.titleSweden, { y: '-100%' , autoAlpha:0, transformOrigin: '0 0' });
		TweenLite.set(el.titleSwitzerland, { y: '0%', transformOrigin: '0 0' });
		TweenLite.set(el.changeBackground, { backgroundColor: '#8B7E70' });
		//TweenLite.set(el.changeBrand, { backgroundColor: '#122C45' });
	};

	var displaySwitzerland = function()
	{
		if (isAnimLocked === true || el.triggerSwitzerland.hasClass('is-active') === true)
		return;

		lockAnim();

		var tlDisplaySwitzerland = new TimelineMax({});
		tlDisplaySwitzerland
		.to(el.imageSwitzerland, .6, { y: '0%' })
		.to(el.imageSweden, .6, { y: '-100%' }, '=-.6')
		.to(el.titleSwitzerland, .6, { y: '0%', autoAlpha: 1} , '=-.6')
		.to(el.titleSweden, .6, { y: '-100%', autoAlpha: 0  }, '=-.6')
		.to(el.changeBackground, .6, { backgroundColor: '#8B7E70' }, '=-.6')
		.to(el.changeFill, .6, { fill: '#8B7E70' }, '=-.6')
		.to(el.changeBorder, .6, { borderColor: '#8B7E70' }, '=-.6')
		//.to(el.changeBrand, .6, { backgroundColor: '#122C45', ease:Power0.easeNone }, '=-.6')
		.addCallback(function() { lockAnim(false); });

		el.triggerSwitzerland.addClass('is-active');
		el.triggerSweden.removeClass('is-active');
	};

	var displaySweden = function()
	{
		if (isAnimLocked === true || el.triggerSweden.hasClass('is-active') === true)
		return;

		lockAnim();

		var tlDisplaySweden = new TimelineMax({});
		tlDisplaySweden
		.to(el.imageSweden, .6, { y: '0%'})
		.to(el.imageSwitzerland, .6, { y: '100%' }, '=-.6')
		.to(el.titleSweden, .6, { y: '0%', autoAlpha: 1 },  '=-.6')
		.to(el.titleSwitzerland, .6, { y: '100%', autoAlpha: 0  }, '=-.6')
		.to(el.changeBackground, .6, { backgroundColor: '#E3B23C' }, '=-.6')
		.to(el.changeFill, .6, { fill: '#E3B23C' }, '=-.6')
		.to(el.changeBorder, .6, { borderColor: '#E3B23C' }, '=-.6')
		//.to(el.changeBrand, .6, { backgroundColor: '#E3B23C', ease:Power0.easeNone }, '=-.6')
		.addCallback(function() { lockAnim(false); });

		el.triggerSweden.addClass('is-active');
		el.triggerSwitzerland.removeClass('is-active');
	};

	var lockAnim = function(status)
	{
		isAnimLocked = status !== false;
	};

	$(document).ready(callback);
	$(document).on('pjax:callback', callback);
})();
