var _Sroller = (function()
{
	var el = {};

	var callback = function()
	{
		initDOM();

		if (el.scroller_box.length > 0) {
			initScroller();
		}
	};

	var initDOM = function()
	{
		el = {};
		el.scroller_box = $('[data-team-scroller]');
	};

	var initScroller = function()
	{
		Draggable.create('[data-team-scroller]', { type: 'left', bounds: '.backgrounds-image', edgeResistance: 0.5, throwProps: true });
		// Draggable.create('[data-fixing-scroller]', { type: 'ScrollLeft', edgeResistance: 0.5, throwProps: true });
	};

	$(document).ready(callback);
	$(document).on('pjax:callback', callback);
})();
