var _Twitter = (function()
{
	var el = {};

	var callback = function()
	{
		initTwitter();
	};

	var initTwitter = function()
	{
		if (initDOM() === false || twttr === undefined || twttr.widgets == undefined)
			return;

		twttr.widgets.createTimeline({
			sourceType: "profile",
			screenName: "GottexBrokers",
		}, document.getElementById("twitter-list"));
	};

	var initDOM = function()
	{
		el = {};
		el.twitter_box = $('[data-twitter-list]');

		return el.twitter_box.length > 0;
	};

	callback();
	$(document).on('pjax:callback', callback);
})();
