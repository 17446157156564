var _Loader = (function()
{
	var el = {};

	var callback = function()
	{
		initDOM();

		if (el.loader_box.length > 0)
			initLoader();
	};

	var initDOM = function()
	{
		el = {};
		el.loader_box = $('[data-loader]');
		el.loader_content = $('[data-loader-content]');
		el.loader_anim_circle = $('[data-loader-anim-circle]');
		el.loader_anim_path = $('[data-loader-anim-path]');
	};

	var initLoader = function()
	{
		TweenLite.set(el.loader_anim_path, { autoAlpha:0 });

		var offsetLeft = $('.brand').offset().left;
		var tlLoader = new TimelineMax();

		tlLoader
		.staggerTo(el.loader_anim_path, 0.01, { autoAlpha: 1 }, .03)
		.to(el.loader_content, .4, { padding: 8, width: 126, height: 48, delay: .5 })
		.to(el.loader_box, .4, { padding: 0, width: 141, height: 48, left: offsetLeft-8, top:0 }, '=-.4')
		//.set(el.loader_content,  { position: 'absolute', })
		.to(el.loader_box, .4, { autoAlpha: 0, delay:.4})
		.set(el.loader_box, { display: 'block' })
		//.set('main', { height: '100vh' });

		// TEMP
		// tlLoader.progress(1);
	};

	$(document).ready(callback);
	$(document).on('pjax:callback', callback);
})();
