var _FixedBar = (function()
{
	var el = {};
	var fixedBarPosition = 0;

	var callback = function()
	{
		initFixedBar();
	};

	var initFixedBar = function()
	{
		if (initDOM() === false)
			return;

		initEvents();

		fixedBarPosition = el.fixed_bar.offset();
		TweenLite.set('[data-fixed-bar]', { x: -26 });
	};

	var initDOM = function()
	{
		el = {};
		el.fixed_bar = $('[data-fixed-bar]');

		return el.fixed_bar.length > 0;
	};

	var initEvents = function()
	{
		$(document)
			.on('click', '[data-anchor]', function(e) { goToAnchor(e, $(this)); })
			.on('scroll', function() { pinToTop(); });
	};

	var goToAnchor = function(e, $anchor)
	{
		e.preventDefault()

		var target = '#' + $anchor.attr('data-anchor');
		var $target = $(target);

		if ($target.length === 0)
			return;

		$('[data-anchor]').removeClass('is-scrolled')
		$anchor.addClass('is-scrolled')

		TweenLite.to('main', .8, { scrollTo: target });
	};

	var pinToTop = function()
	{
		if ($('body').scrollTop() > fixedBarPosition.top) {
			$('[data-fixed-bar]').css({ 'position': 'fixed', 'top': '0' });
		}
		else {
			$('[data-fixed-bar]').css('position', 'relative');
		}
	};

	callback();
	$(document).on('pjax:callback', callback);
})();
